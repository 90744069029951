import { projectFirestore } from "../firebase/config";
import getCollection from "./getCollection";
import useCookies from "./useCookies";
import { ref } from "vue";
const userCollection = () => {
	const { getCookie } = useCookies();
	const users = ref(null);
	const thirdpertyusers = ref(null);
	const error = ref("");

	const getUser = async () => {
		try {
			const companyId = getCookie("rm_cId");
			let collectionRef = projectFirestore
				.collection("companies")
				.doc(companyId)
				.collection("employees")
				.where("isActive", "==", true);

			const { documents: items } = getCollection(collectionRef);
			users.value = items;
		} catch (err) {
			console.log(err.message);
			error.value = err.message;
		}
	};

	const getEmployeeProfile = async (userId, companyId) => {
		let employee;

		const employeeRef = projectFirestore
			.collection("companies")
			.doc(companyId)
			.collection("employees")
			.doc(userId);

		const doc = await employeeRef.get();

		if (doc.exists) {
			employee = { id: doc.id, ...doc.data() };
		}

		return employee;
	};

	const getUserForThirdParty = async (id) => {
		try {
			const companyId = getCookie("rm_cId");
			let collectionRef = projectFirestore
				.collection("companies")
				.doc(id)
				.collection("employees")
				.where("isActive", "==", true);

			const { documents: items } = getCollection(collectionRef);
			thirdpertyusers.value = items;
		} catch (err) {
			console.log(err.message);
			error.value = err.message;
		}
	};

	const getEmployeesFromList = async (userIDs, companyId) => {
		error.value = null;
		const employees = [];

		// Set the maximum batch size for each query
		const batchSize = 10;

		// Split the user IDs into batches
		const batches = [];
		for (let i = 0; i < userIDs.length; i += batchSize) {
			const batch = userIDs.slice(i, i + batchSize);
			batches.push(batch);
		}

		// Create an array to store the promises for each query
		const queryPromises = [];

		batches.forEach((batch) => {
			const employeesRef = projectFirestore
				.collection("companies")
				.doc(companyId)
				.collection("employees");

			const promises = batch.map((userId) => {
				const docRef = employeesRef.doc(userId);
				return docRef.get();
			});

			const promise = Promise.all(promises);
			queryPromises.push(promise);
		});

		// Wait for all queries to complete
		try {
			const querySnapshots = await Promise.all(queryPromises);
			querySnapshots.forEach((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					// Add the employee to the list
					const employee = { id: doc.id, ...doc.data() };
					employees.push(employee);
				});
			});

			return employees;
		} catch (error) {
			console.error("Error getting employees:", error);
			throw error;
		}
	};

	return {
		getUser,
		users,
		error,
		thirdpertyusers,
		getUserForThirdParty,
		getEmployeesFromList,
		getEmployeeProfile,
	};
};

export default userCollection;
